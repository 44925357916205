import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { BFormFile } from 'bootstrap-vue';

@Component({
  components: {
    ValidationProvider,
    BFormFile,
  }
})
export default class DragDropFileInput extends Vue {
  file = null;

  mounted(): void {
    document.addEventListener('paste', this.onPasteEventListener)
  }

  beforeDestroy() {
    document.removeEventListener('paste', this.onPasteEventListener);
  }

  onPasteEventListener(event) {
    const content = event.clipboardData.items[0];
    if (content && content.kind === 'file') {
      this.file = content.getAsFile();
      this.onFileChoose();
    }
  }

  onFileChoose() {
    this.$emit('file-select', this.file);
  }
}
