import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import { showToast } from '@/helpers/toast.helper';
import { ReportSourcesCreateRequest } from '@/types/request/report-sources-request.types';
import { BrandType } from '@/types/api/brands/brand.type';
import DragDropFileInput from '@/components/ui/inputs/drag-drop-file-input/drag-drop-file-input.vue';

@Component({
  name: 'UploadReportModal',
  components: {
    ValidationObserver,
    BTextInputValidation,
    BSelectInputValidation,
    DragDropFileInput,
  },
})
export default class UploadReportModal extends Vue {
  tryingToUpdate = false;
  brandId = null;
  type = null;
  file: null | File = null;

  @Prop({
    required: true
  })
  updateCallback!: (response: any) => any;

  @Action('createReportSources', { namespace: 'reportSources' })
  createReportSources!: (params: ReportSourcesCreateRequest) => Promise<void>;

  @Action('getBrands', { namespace: 'brands' })
  getBrands!: () => Promise<BrandType[]>;

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.getBrands();
  }

  cancel() {
    this.$emit('close');
  }

  get brandOptions() {
    return this.brands.map(item => ({ text: item.displayName, value: item.id }));
  }

  get typeOptions() {
    return [
      { text: 1, value: 1 },
      { text: 2, value: 2 },
      { text: 3, value: 3 },
      { text: 4, value: 4 },
      { text: 5, value: 5 },
      { text: 6, value: 6 },
      { text: 7, value: 7 },
      { text: 8, value: 8 },
    ]
  }

  onFileSelect(file) {
    this.file = file;
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      this.createReportSources({
        Type: this.type!,
        BrandId: this.brandId!,
        source: this.file as File
      })
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          showToast(this.$t('reportSource.successUpload') as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          showToast(this.$t('reportSource.errorUpload') as any);
        });
    }
  }
}
