import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import { GridTableApi } from '@/components/ui/grid-table/gridTable';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportSourceType } from '@/types/api/report-sources/report-source.type';
import { getShowingDate } from '@/helpers/date.helper';
import UploadReportModal from '@/components/features/modals/upload-report-modal/upload-report-modal.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';

const title = 'reportSource.title';

@Component({
  name: 'Orders',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    GridTable,
    BoxHeader,
  },
})
export default class ReportSource extends Mixins(FilterMixin) {
  title = title;
  table: GridTableApi | null = null;

  @Action('getReportSources', { namespace: 'reportSources' })
  getReportSources!: () => Promise<ReportSourceType[]>;

  @Getter('getReportSources', { namespace: 'reportSources' })
  reportSources!: ReportSourceType[];

  created() {
    this.getReportSources();
  }

  get tableFields() {
    return [
      {
        label: this.$t('reportSource.id'),
        key: 'id',
      },
      {
        label: this.$t('reportSource.fileName'),
        key: 'fileName',
      },
      {
        label: this.$t('reportSource.status'),
        key: 'statusDescription',
      },
      {
        label: this.$t('reportSource.type'),
        key: 'reportTypeDescription',
      },
      {
        label: this.$t('reportSource.recordsCount'),
        key: 'recordsCount',
      },
      {
        label: this.$t('reportSource.uploadTime'),
        key: 'uploadTime',
        formatter: (value: string) => getShowingDate(value)
      },
      {
        label: this.$t('reportSource.uploadedUser'),
        key: 'uploadedUserEmail',
      },
    ];
  }

  onUpload() {
    this.$modal.show(UploadReportModal, {
      updateCallback: () => {
        this.getReportSources();
      }
    }, MODAL_CONFIG);
  }
}
